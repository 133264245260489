// ################################################################## 
// TOC
// CUSTOM PROPERTIES
// UTILITIES
// BODY
// HEADER
// SEARCH
// ANNOUNCEMENTS
// MAIN NAVIGATION
// PORTAL SLIDER
// XXSS
// PORTAL ICONS
// DIRECT LINKS HOME
// BANNER ALCALDE
// NEWS HOME
// AGENDA HOME
// AGENDA INTERIOR
// TRAMITS
// INFO
// BARRIS I DISTRICTES
// BANNERS
// FOOTER
// HEADER INTERIOR
// BREADCRUMBS
// NAVEGACIO LATERAL
// RELATED
// BLOGS
// SEARCH EVENTS / NEWS
// MINISITES
// GRUPS MUNICIPALS
// EQUIPAMENTS
// PROJECTS
// ALBUM
// RESULTAT CERCA
// LANDING
// PRESS ROOM
// BEACHES
// RATE
// A11Y
// HIGH CONTRAST
// PRINT

@use 'sass:math';

// ################################################################## 
// CUSTOM PROPERTIES
:root {
    --bdn-control-color:       #{$primary};
    --bdn-brand-facebook:       #4267B2;
    --bdn-brand-twitter:        #1DA1F2;
    --bdn-brand-whatsapp:       #25D366;
}
 
// ################################################################## 
// UTILITIES
.image-169 {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}
.image-43 {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;
    object-fit: cover;
}
.image-11 {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
.font-heading {
    font-family: $headings-font-family !important;
}
.zindex-flat {
    z-index: $zindex-flat;
}
.zindex-dropdown {
    z-index: $zindex-dropdown;
}
.zindex-fixed {
    z-index: $zindex-fixed;
}
b,
strong {
  font-weight: $font-weight-semibold;
}
.max-80 {
    max-width: 50rem;
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    .bg-split-md {
        background-image: linear-gradient(to right, var(--bs-primary) 0%, var(--bs-primary) 50%, var(--bs-blue-dark) calc(50% + 1px) , var(--bs-blue-dark) 100% );
    }
}
.btn-blue-dark.btn {
    &:hover, &:focus {
        --bs-btn-hover-color: #{$blue-900};
        --bs-btn-hover-bg: #{$white};
        --bs-btn-hover-border-color: #{$white};
    }
}
// 1024 pixels - 64em
@media (min-width: map-get($grid-breakpoints, "lg")) {
    .visually-hidden.visually-visible-lg {
        position: static !important; 
        width: auto !important;
        height: auto !important;
        clip: auto !important;
        margin: 0 !important;
    }
    
}

// ################################################################## 
// BODY
body:not(.col-one) #container-content {
    @extend .container;
}
body.col-one #container-content {
    @extend .container-fluid;
}
// 1600 pixels - 100em
@include media-breakpoint-up(xxl) {
    body.col-one.col-two.col-content{ 
        #portal-column-content {
            max-width: 50rem;
        }
        #portal-column-two {
            max-width: 29rem;
        }
    }
}

// 1024 pixels - 64em
@media (min-width: map-get($grid-breakpoints, "lg")) {
    body.col-one.col-content #portal-column-one { max-width: 30rem; }
    body.col-one.col-content #portal-column-content { max-width: 85rem;}
    body.col-one.col-content.template-equipment_listing_view #portal-column-content { max-width: none; width: 100%;}
    body:not(.frontend) #content { max-width: map-get($container-max-widths, 'xl'); margin-left: auto;margin-right: auto;  }
}

// 1440 pixels - 90em
@media (min-width: map-get($grid-breakpoints, "xxl" )) {
    body:not(.frontend) #content { max-width: map-get($container-max-widths, 'xxl'); }
}


a {
    color: $body-color;
    text-decoration-color: var(--bs-link-color);
    text-decoration-thickness: 2px;
    text-underline-offset:.25rem;
}

 
// ################################################################## 
// HEADER
.headerBar {
    .logo__img {
        height: 2.5rem;
        width: auto;
    }
    &__extraButtons {
        button, a {
            color: var(--bs-white);
            text-decoration: none;
            &:hover, &:focus {
                color: var(--bs-gray-dark);
            }
        }
        .a11ymn__btn {
            color: var(--bs-body-color);            
        }       
    }

    @media (min-width: map-get($grid-breakpoints, "lg")) {
        position: relative;
        .logo__img {
            height: 3.5rem;
        }
        &::after {
            content: '';
            display: block;
            background-color: var(--bs-primary);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: calc(50% - 468px);
        }
    }

    @media (min-width: map-get($grid-breakpoints, "xl")) {
        &::after {
            width: calc(50% - 558px);
        }
    }

    @media (min-width: map-get($grid-breakpoints, "xxl")) {
        &::after {
            width: calc(50% - 758px);
        }
    }
}
.shareBtn {
    .mnPrincipal &__list .dropdown-item { white-space: nowrap;}
    &--facebook { background-color: var(--bdn-brand-facebook); color: var(--bs-white);}
    &--twitter { background-color: var(--bdn-brand-twitter); color: rgba(var(--bs-body-color-rgb), .8) !important;}
    &--whatsapp { background-color: var(--bdn-brand-whatsapp); color: rgba(var(--bs-body-color-rgb), .8) !important;}
    &--mail { background-color: $gray-700; color: var(--bs-white) !important;}
}

// Font size
html[style="font-size: 24px;"] .fontSwitch--big,
html[style="font-size: 16px;"] .fontSwitch--regular,
html[style="font-size: 12px;"] .fontSwitch--small { background-color: var(--bs-primary) !important; color: var(--bs-white)}

.breadAndTitle {
    body:not(.col-one) &__wrapper {
        @extend .container;
    }
    body.col-one & {
        @extend .container-fluid;
        &__wrapper {
            // 480 pixels - 30em
            @media (min-width: map-get($grid-breakpoints, "md")) {
                padding-left: 3rem;
            }
        }
    }
}

// ################################################################## 
// SEARCH
.searchModal {
    &.modal {
        --bs-modal-bg: rgba(var(--bs-blue-medium-rgb), .5);
        --bs-modal-color: #{$white};
        --bs-modal-header-border-width:0;
    }
    .modal-content { 
        backdrop-filter: blur(2px);
        --webkit-backdrop-filter: blur(2px);
    }
    &__wrapper {
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
    }
    .input-group {
        .form-control {
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
        }
    }
    .btn-close {
        filter: invert(100%);
        opacity: 1;
    }
    // 1024 pixels - 64em
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        .livesearch-results {
            width: 100%;
            margin-top: 0.5rem;
        }
    }
}

// ################################################################## 
// ANNOUNCEMENTS
.announcements { 
    .btn-link {
        --bs-btn-color: var(--bs-white);
    }
    .carousel-item{
        min-height: 4.5em;
    }
    .owl-prev, .owl-next {
        background-color: transparent;
        border: none;
        color: var(--bs-white);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &:hover, &:focus {
            color: var(--bs-blue-dark);
            .carousel-control-next-icon,
            .carousel-control-prev-icon {
                filter: brightness(50%);
            }
        }
    }
    .owl-prev {
        left: 0;
    }
    .owl-next {
        right: 0;
    }
    

    @media (min-width: map-get($grid-breakpoints, "md")) {
        .carousel-item{
            min-height: 3em;
        }    
    }

    @media (min-width: map-get($grid-breakpoints, "lg")) {
        .carousel-item{
            min-height: 0;
        }    
    }
}
.stopAvisos{
    &[data-paused="false"] {
        .stopAvisos__icon--play { display: none;}
        .stopAvisos__icon--pause { display: inline-block;}
    }
    &[data-paused="true"] {
        .stopAvisos__icon--play { display: inline-block;}
        .stopAvisos__icon--pause { display: none;}
        background-color: var(--bs-white);
        color: var(--bs-danger);
    }
}

// ################################################################## 
// MAIN NAVIGATION
.mnPrincipal {
    // Custom properties
    --bs-nav-link-font-size: #{$font-size-sm};
    --bs-navbar-background: transparent;
    --bs-navbar-nav-link-padding-y: .5rem;
    --bs-navbar-hover-background: transparent;
    --bs-navbar-hover-color: #{$blue-600};
    --bs-navbar-active-background: transparent;
    &.navbar {
        --bs-navbar-toggler-focus-width:0;
    }
    .navbar-nav {
        li ul {
            opacity: 1;
            visibility: visible;
            height: auto;
        }
        & > .dropdown-menu{
            --bs-dropdown-bg: var(--bs-blue-light);
            --bs-dropdown-border-color: transparent;
        }        
    }
    .dropdown-toggle {
        display: flex;
        align-items: center;
        &::after {
            margin-left: auto;
            margin-right: 16px;
        }
        // 768 pixels - 48em
        @media (min-width: map-get($grid-breakpoints, "md") ) {
            &::after {
                margin-left: .5rem;
            }
        }
    }
    .dropdown-menu {
        --bs-dropdown-padding-x:0;
        --bs-dropdown-item-padding-y: 0;
        --bs-navbar-nav-link-padding-x: 0;
        --bs-dropdown-border-radius: 0;
        --bs-dropdown-bg: var(--bs-blue-light);
        --bs-dropdown-font-size: #{$font-size-sm};
        --bs-link-color: var(--bs-blue-dark);
        box-shadow: none;
    }
    .dropdown-item {
        white-space: normal;
    }
    .nav-link {
        --bs-nav-link-color: var(--bs-blue-dark);
    }
    .nav-item {
        border-bottom: 1px solid var(--bs-blue-dark);
        &:last-child {
            border-bottom: none;
        }
    }
    &__buttons {
        display: flex;
        justify-content: space-around;
        height: 50px;
        align-items: center;
        margin: 0 calc(var(--bs-gutter-x) * -0.5) -8px calc(var(--bs-gutter-x) * -0.5);
        a, button {
            --bs-btn-color: var(--bs-white);
            text-decoration: none;
        }
        .dropdown-toggle::after {
            content: none;
        }
        @media (min-width: map-get($grid-breakpoints, "lg")) {
            body:not(.landing) & { display: none; }
        }
    } 
    ul.mnPrincipal__segon__list {
        position: relative !important;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        &.navbar {
            --bs-navbar-padding-y:0;
            --bs-navbar-nav-link-padding-y: .8rem;
            li.current > a, .navbar-nav li.active > a, .navbar-nav li.selected > a {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 0.25rem;
                    background-color: var(--bs-navbar-hover-color);
                }
            }
        }
        .navbar-nav {
            li {
                position: static;
            }
            .dropdown-menu {
                width: 100%;
                box-shadow: var(--bs-dropdown-box-shadow);
                body.landing & {
                    box-shadow: none;
                }
            }
        }
        .nav-item {
            border-bottom: none;
       }
       &__segon {
            &.dropdown-menu.show {
                --bs-dropdown-zindex: 1001;
                display: flex;
            }
            &__list {
                flex: 1 0 66%;
                columns: 2;
                column-gap: 2.5rem; //40px
                column-rule: 1px solid var(--bs-blue-medium);
                li {
                    break-inside: avoid;
                }
            }
            &__banner {
                border-left: 1px solid var(--bs-blue-medium);
                flex: 0 0 33%;
            }
       }
    }

    // 1280 pixels - 80em
    @media (min-width: map-get($grid-breakpoints, "xl")) {
        .dropdown-menu {
            --bs-dropdown-padding-y: 1rem;
        }
        &__segon { 
            &__list {
                .mnPrincipal__segon--noBanner & {
                    flex-basis: 100%;
                    columns: 3;
                }
            }
        }
    }
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(-121 -130)'%3E%3Crect width='30' height='30' transform='translate(121 130)' fill='none'/%3E%3Cline y1='23' x2='23' transform='translate(124.5 133.5)' fill='none' stroke='%23616a6f' stroke-width='2'/%3E%3Cline x1='23' y1='23' transform='translate(124.5 133.5)' fill='none' stroke='%23616a6f' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
}

// ################################################################## 
// PORTAL SLIDER
.portalSlider {   
    --bs-link-color: var(--bs-blue-dark);
    &__item {
        &:hover, &:focus-within {
            .portalSlider__readmore {
                --bs-btn-bg: var(--bs-white);
                --bs-btn-color: var(--bs-blue-dark);
            }
        }
    }
    &__image {
        height: auto;
    }
    &__link {
        text-decoration: none;
    }
    &__text {
        transition:  $transition-base;
        z-index: $zindex-flat;
        & > * {
            max-width: 60%;
        }
    }
    &:hover, &:focus-within {
        color: var(--bs-white);
        .portalSlider__text {
            background-color: rgba(var(--bs-black-rgb) , .6);
            color: var(--bs-white);
        }
        .portalSlider__link,
        .portalSlider__title {
            color: var(--bs-white);
        }
    }
    
}
.bdnSlideDots {
    .owl-dot {
        transition: $transition-base;
        width: 1.25rem;
        height: 0.625rem;
        background-color: var(--bs-white);
        box-shadow: 0px 0px 6px rgba(var(--bs-body-color-rgb), 0.16);
        border: none;
        border-radius: 0.5rem; 
        &.active {
            width: 2.5rem;
            background-color: var(--bs-primary);
        }
        & + .owl-dot {
            margin-left: 1rem;
        }
        &:hover, &:focus {
            background-color: var(--bs-blue-medium);
        }
    }
}
.stopSlides {
    &[data-paused="true"] {
        .stopSlides__icon--play { display: inline-block;}
        .stopSlides__icon--pause { display: none;}
    }
    &[data-paused="false"] {
        .stopSlides__icon--play { display: none;}
        .stopSlides__icon--pause { display: inline-block;}
    }
    &.btn-link {
        color: var(--bs-primary);
        filter: drop-shadow(0 0 6px rgba(var(--bs-body-color-rgb),0.16));
        &[data-paused="true"] {
            color: var(--bs-white);
        }
        &:hover{
            color: var(--bs-blue-light);
        }
    }
}

// ################################################################## 
// XXSS
.xxss {
    &.mnPrincipal__buttons {
        justify-content: center;
        height: auto;
        margin-bottom: 0;
    }
}

// ################################################################## 
// PORTAL ICONS
// TODO: esborrar si és correcta la nova versió
// .portalIcons {
//     --bdn-icon-color: var(--bs-blue-dark);
//     &__wrapper {
//         display: flex;
//         margin-left: auto;
//         margin-right: auto;
//         justify-content: center;
//         align-items: center;
//         height: 72px;
//         width: 72px;
//         border-radius: 50%;
//         background-color: var(--bs-blue-light);
//         margin-bottom: 8px;
//     }
//     &__icon {
//         width: 40px;
//         height: auto;
//     }
//     &__text {
//         text-align: center;
//         line-height: $headings-line-height;
//         font-size: $font-size-base;
//     }
//     &__link {
//         text-decoration: none;
//         color: var(--bs-body-color);
//     }
//     &__item {
//         transition: $transition-base;
//         &:hover, &:focus-within {
//             --bdn-icon-color: var(--bs-white);
//             --bs-blue-light: var(--bs-primary);
//         }
//     }
// }
.portalIcons {
    &__img {
        width: 100%;
        height: auto;
        aspect-ratio: 3 / 1;
        object-fit: cover;
    }
    &__text {
        font-size: $h6-font-size;
        text-align: center;
        padding: 0.5rem;
        background-color: var(--bs-white);
        line-height: $headings-line-height;
        font-family: $headings-font-family;
    }
    &__link {
        text-decoration: none;
        color: var(--bs-body-color); 
    }
}

// ################################################################## 
// DIRECT LINKS HOME
.dili {
    &__image {
        transition: $transition-base;
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
        object-fit: cover;
    }
    &__link:hover, &__link:focus {
        img {
            transform: scale(1.15);
        }
    }
}

// ################################################################## 
// BANNER ALCALDE
.bannerAlcalde { 
    &__image {
        width: 100%;
        height: auto;
        transition: $transition-base;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        object-position: center top;
    } 
    &:hover, &:focus-within {
        .bannerAlcalde__image {
            transform: scale(1.1);
        }
        .bannerAlcalde__text { 
            color: var(--bs-primary);
        }
        .bannerAlcalde__link {
            color: var(--bs-primary);
        }
    }
    &__link {
        transition: $transition-base;
        color: var(--bs-body-color);
        &:hover, &:focus {
            color: var(--bs-primary);
        }
    }
    // 992 pixels - 62em
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        height: 100%;
        &__image {
            aspect-ratio: auto;
            height: 100%;
            position: absolute;
            inset: 0;
        }
    }
}

// ################################################################## 
// NEWS HOME
.newsItem {
    &__subject + &__subject {
        margin-left: 8px;
        padding-left: 8px;
        border-left: 1px solid currentColor;
    }
    &:hover , &:focus-within {
        .btn-light{
            --bs-btn-bg: var(--bs-primary);
            --bs-btn-color: var(--bs-white);
        }
    }
}

// ################################################################## 
// AGENDA HOME
.events {
    &__filter {
        --bs-gutter-x: 2px;
        --bs-gutter-y: 2px;
    }
}
// 768 pixels - 48em
@media (min-width: map-get($grid-breakpoints, "md")) {
    #filterList {
        display: block !important;
    }
}
.selectorEvents {
    display: block;
    height: 100%;
    cursor: pointer;    
    &__text { 
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align:center;
        padding: 16px;
        color: var(--bs-blue-medium);
        background-color: var(--bs-blue-light);
        font-family: $headings-font-family;
        input:checked + & {
            background-color: var(--bs-blue-medium);
            color: var(--bs-white);
            &::before {
                content: '';
                background-position: center center;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");                background-repeat: no-repeat;
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }
        &:hover {
            background-color: $blue-200;
        }
    }
    input {
        opacity: 0;
        position: absolute;
        &:focus {
            & + .selectorEvents__text {
                background-color: $blue-200;
            }
        }
    }
}

.eventItem {
    &__date {
        z-index: $zindex-flat + 1;
        font-family: $headings-font-family;
        &__month[title] {
            text-decoration: none;
        }
    }
}
.eventItem, .newsItem {
    &__image {
        transition: $transition-base;
        transform: scale(1);
    }
    &:hover, &:focus {
        .newsItem__image,
        .eventItem__image {
            transform: scale(1.1);
        }
        .newsItem__link,
        .eventItem__link {
            color: $primary !important;
        }
    }
}

// ################################################################## 
// AGENDA INTERIOR
.event.summary {
    --bs-bg-opacity: .2;
}

// ################################################################## 
// TRAMITS
.tramits {
    .form-control {
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;
        padding-top: .8125rem;
        padding-bottom: .8125rem;
    }
    .btn {
        --bs-btn-border-radius: 1.75rem;
    }
    &__list {
        columns: 2 300px;
        column-gap: var(--bs-gutter-x);
        li {
            break-inside: avoid;
        }
    }
}

// ################################################################## 
// INFO
.infoHome {
    background: url(../img/theme++badalona/bg-info.jpg) no-repeat center center / cover var(--bs-primary);  
    background-image: var(--bdn-bgInfo-sm, url(../img/theme++badalona/bg-info.jpg) );
    // 768 pixels - 48em
    @media (min-width: map-get($grid-breakpoints, "md")) {
        background-image: var(--bdn-bgInfo-md, url(../img/theme++badalona/bg-info.jpg) );
    }
    // 1024 pixels - 64em
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        background-image: var(--bdn-bgInfo-lg, url(../img/theme++badalona/bg-info.jpg) );
    }
}
.ecoInfo {
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(8px)  brightness(33%);
    --webkit-backdrop-filter: blur(8px)  brightness(33%);
    background-color: rgba(var(--bs-primary-rgb), 0.15);
    max-width: 40rem;
    margin-left: auto;
    /* (plus shape's fill blended on top as a separate layer with 80% opacity) */
    &__image {
        width: 5.5rem;
        height: auto;
    }
}
.platgesInfo {
    .alternative_text {
        max-width: 40rem;
        margin-right: auto;
    }
    a {
        color: var(--bs-link-color);
    }
    // Video
    iframe {
        max-width: 100%;
        width: 100%;
        aspect-ratio: 16/9;
        height: auto;
    }
  

}

// Weather
.weather-circle {  width: .625rem; height: .625rem; display: inline-block; border-radius: 50%; background-color: $white;}
.white-circle { background-color: $white;}
.orange-circle { background-color: $orange;}
.green-circle { background-color: var(--bs-teal);}
.red-circle { background-color: $red;}

// ################################################################## 
// BARRIS I DISTRICTES
.barris {
    --bdn-mapColor: var(--bs-blue-medium);
    .nav-tabs{
        --bs-nav-tabs-link-active-border-color: transparent;
        --bs-nav-tabs-border-color: transparent;
        --bs-nav-tabs-link-active-color: var(--bs-primary);
        --bs-nav-link-color: #{$gray-500};
    }
    .nav-item  {
        & + .nav-item {
            border-left: 2px solid var(--bs-primary);
        }
    }
    &__map {
        width: 100%;
        height: auto;
        max-width: 22rem;
        .mapArea {
            transition: $transition-base;
            &:hover, &--activa {
                --bdn-mapColor: var(--bs-blue-light);
                cursor: pointer;
            }
        }
    }
    &__list {
        ul {
            columns: 2;
            li {
                break-inside: avoid;
            }
        }
        &__item, &__districte {
            &:hover, &:focus {
                color: var(--bs-white) !important;
                background-color: var(--bs-primary);
            }
        }
    }
}

// ################################################################## 
// BANNERS
.bannersFooter {
    .owl-carousel .owl-stage {
        display: flex;

    }
    .card {
        --bs-card-border-width:0;
        .card-img-top {
            transition: $transition-base;
        }
        &:hover, &:focus-within {
            .card-img-top {
                transform: scale(1.1);
            }
        }
    }
    .owl-carousel .owl-stage-outer {
        overflow: visible;
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300598C'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300598C'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    }
}

.owlBtn-links {
    .owl-prev, .owl-next {
        border: none;
        background-color: transparent;
        color: var(--bs-blue-medium);
    }

}

// ################################################################## 
// FOOTER
.portalFooter {
    &__logo { 
        img {
            width: 16.25rem;
            height: auto;
        }
    }
    .navbar-dark .navbar-nav{
        --bs-navbar-color: rgba(var(--bs-white-rgb), .80);
        --bs-nav-link-hover-color: rgba(var(--bs-white-rgb), 1);
        --bs-navbar-background: transparent;
        --bs-nav-link-padding-y: .5rem;
        --bs-nav-link-padding-x: .5rem;
    }
    .nav-link {
        white-space: nowrap;
        &:hover, &:focus {
            border-bottom: 1px solid currentColor;
        }
    }
    
    @media (min-width: map-get($grid-breakpoints, "sm")) {
        &__logo {
            width: 22rem;
            img {
                width: 100%;
            }
        }
    }

    @media (min-width: map-get($grid-breakpoints, "md")) {
        &__logo {
            width: auto;
            img {
                width: 18rem;
            }
        }
    }
}

// ################################################################## 
// HEADER INTERIOR
.pageHeader img{
    height: auto;
    width: 100%;    
}

// ################################################################## 
// BREADCRUMBS
.bdnBreadcrumbs {
    --bs-link-color: #{$gray-600};
    a {
        text-decoration: none;
    }
}

// ################################################################## 
// NAVEGACIO LATERAL
@media (min-width: map-get($grid-breakpoints, "md")) {
    body.col-one.col-content #portal-column-one {
        padding-left: 0;
    }
}
.portletNavigationTree {
    &.portlet {
        box-shadow: none;
    }
    &.card {
        max-width: 20rem;
        --bs-card-box-shadow: none;
        --bs-card-border-color: transparent;
    }
    .card-body {
        background-color: var(--bs-blue-light);
        padding: 1.5rem 1rem;
        .navTreeCurrentNode{ 
            & > a{
                background-color: var(--bs-white);
                color: var(--bs-body-color);
                border-left: 4px solid var(--bs-primary);
                &::after {
                    content:none;
                }
            }
        }
        ul li a:hover::after {
            content: none;
        }
    }
    .navTree {
        padding-left: 0;
        list-style: none;
        font-size: var(--bs-body-font-size);
        ul {
            --bs-body-font-size: #{$font-size-sm};
            margin-top: 2px;
            .navTreeItem + .navTreeItem{
                margin-top: 0;
            }
            a {                
                font-family: $font-family-sans-serif;
            }
        }
        .navTreeItem + .navTreeItem{
            margin-top: 0.5rem;
        }
        a {
            transition: $transition-base;
            display: block;
            background-color: var(--bs-white);
            text-decoration: none;
            padding: .5rem;
            color: rgb(var(--bs-body-color-rgb));
            font-family: $headings-font-family;
            &:hover, &:focus {
                background-color: var(--bs-primary);
                color: var(--bs-white);
            }
        }
    }
}

// ################################################################## 
// RELATED
.relatedItem {
    border-radius: var(--bs-border-radius);
    padding: 1rem;
    text-align: center;
    &.bg-blue-light { 
        &:hover, &:focus-within {
            --bs-bg-opacity: .8;
            --bs-blue-light-rgb: var(--bs-blue-medium-rgb);
        }
    }
    &__image {
        font-size: 5rem;
        color: var(--bs-primary);
        margin-bottom: 0.5rem;
    }
    &__link {
        display: block;
        font-size: $font-size-base;
        text-decoration: none;
        color: var(--bs-body-color);
        margin-bottom: 0.5rem;
        font-family: $headings-font-family;
    }
    &__size {
        font-size: $font-size-sm;
        text-transform: uppercase;
        color: var(--bs-blue-medium);
    }
    &:hover, &:focus-within {
        .relatedItem__link, .relatedItem__size {
            color: var(--bs-white);
        }
        .relatedItem__image {
            color: var(--bs-white);
        }
    }
}

// ################################################################## 
// BLOGS
.blogUnfolded {
    &__primer {
        padding: 2.5rem;
        background-color: var(--bs-blue-medium);
        color: var(--bs-white);
        &:hover, &:focus {
            background-color: var(--bs-blue-dark);
            color: var(--bs-white);
        }
    }
    &__segon { 
        background-color: var(--bs-blue-light);
        color: var(--bs-body-color);
        &:hover, &:focus {
            background-color: var(--bs-primary);
            color: var(--bs-white);
            --bs-primary-rgb: 255, 255 , 255;
        }
    }
}
.blogItem {
    --bs-card-border-color: transparent;
    .bg-blue-light {
        --bs-bg-opacity: .20;
    }
    .card-img-top {
        object-fit: cover;
        aspect-ratio: 4.5/1;
    }
}
.blog2item {
    &__image {
        transition: $transition-base;
        transform: scale(1);
    }
    &__link {
        color: var(--bs-blue-dark);
    }
    &:hover, &:focus-within {
        --bs-blue-dark: var(--bs-primary);
        .blog2item__image {
            transform: scale(1.1);
        }
    }
}

// ################################################################## 
// SEARCH EVENTS / NEWS
.form-label {
    font-family: $headings-font-family;
}

// ################################################################## 
// MINISITES
body.template-minisite_view,
body.template-districte_view {
    main {
        margin-bottom: 0;
    }
}
.minisiteHeader {
    min-height: 22rem;
    position: relative;
    &__image {
        position: absolute;
        z-index: $zindex-flat - 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 22rem;
        object-fit: cover;
    }
    &__title {
        color: var(--bs-white);
        position: relative;
        z-index: $zindex-flat + 2;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 22rem;
        background-color: rgba(var(--bs-black-rgb) , .6);
        z-index: $zindex-flat;
    }
    &--noSlide::after {
        background-color: transparent;
        background-image: linear-gradient(to bottom, rgba(var(--bs-black-rgb), .6) 0%, transparent 25%, transparent 100%);
    }
    &-noSlide{
        .minisiteHeader__title{ 
            span {
                padding: .5rem;
                background-color: rgba(var(--bs-black-rgb) , .6);
                display: inline-block;
            }
        }
        &::after {
            content: none;
        }
        .minisiteHeader__xxss a {
            background-color: rgba(var(--bs-black-rgb) , .6);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
        }
    }
    &__slide {
        position: relative;
        z-index: $zindex-flat + 2;
        .owl-prev, .owl-next {
            background-color: transparent;
            color: var(--bs-blue-dark);
            border: none;

        }
    }
}
.bdnSlideDots--min-m.bdnSlideDots {
    .owl-dot {
        background-color: var(--bs-blue-light);
        &.active {
            background-color: var(--bs-primary);
        }
    }
}

.minisiteHeader__slide {
    &.bdnSlideDots .owl-dot {
        --bs-white: var(--bs-blue-light);
    }
    .carousel-control-prev-icon { 
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002840'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002840'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
}
.minisiteSlide {
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        &__item {
            display: flex;
            align-items: flex-start;
        }
        &__image {
            flex: 0 0 50%;
            width: 50% !important;
        }
        &__text {
            flex: 1 0 50%;
            padding-bottom: 3rem;
        }
        &__nav {
            position: absolute;
            right: 0;
            padding: 0 2.5rem math.div($grid-gutter-width , 2) 0; 
            width: 50%;
            bottom: 0;
            z-index: $zindex-flat + 4;
        }
    }
}
.minisiteNav {
    --bs-link-hover-color: #{$white};
    &__link, .text-bg-blue-medium {
        &:hover, &:focus {
            background-color: var(--bs-blue-dark) !important;
        }
    }
}
.text-bg-blue-dark, .text-bg-blue-medium, .text-bg-primary {
    --bs-link-color: var(--bs-gray-300);
    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 { 
        color: var(--bs-gray-300); 
    }
    a { color: var(--bs-white);
        &:hover, &:focus {
            color: var(--bs-gray-400);
        }
    }
}

.equipments {
    &__dots {
        max-width: 100%;
        overflow: hidden;
        position: relative;
        min-height: 3rem;
    }
    .owl-dots {
        position: absolute;
        left: 0;top: 0;
        display: flex;
        padding-bottom: 1rem;
        transition: transform .5s;
        .owl-dot {
            color: var(--bs-blue-light);
            position: relative;
            white-space: nowrap;
            opacity: .5;
            background-color: transparent;
            border:none;
            box-shadow: none;
            & + .owl-dot {
                margin-left: 2rem;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 4rem;
                height: 0.4rem;
                top: calc(100% + .5rem);
                left: 0;
                background-color: var(--bs-white);
                transition: $transition-base;
                transform: scaleX(0);
                transform-origin: left center;
            }
            &.active {
                opacity: 1;
                color: var(--bs-white);
                &::after {
                    transform: scaleX(1);
                }
            }
        }
    }
    // 768 pixels - 48em
    @media (min-width: map-get($grid-breakpoints, "md")) {
        &__image.w-25 {
            width: 25% !important;
        }        
    }
    // 1280 pixels - 80em
    @media (min-width: map-get($grid-breakpoints, "xl")) {
        &__image.w-25 {
            width: 33.3% !important;
        } 
    }
}

.progproj {
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 2rem;
            background-image: linear-gradient(to left, var(--bs-primary) 0, transparent 100%);
            z-index: $zindex-flat + 10;
        }
    }
}

// ################################################################## 
// GRUPS MUNICIPALS
.grupMunicipal {
    &__item {
        background-color: rgba(var(--bdn-grup-color), .1);
        a {
            &:hover, &:focus { color: var(--bs-primary) !important; }
        }
    }    
    &__image {
        width: 4rem;
        min-height: 4rem;
        height: auto;
        object-fit: contain;
    }
    &__numbers {
        color: var(--bs-white);
        background-color: rgb(var(--bdn-grup-color));
        display: inline-flex;
        height: 2.5rem;
        align-items: center;
        justify-content: center;
        border-radius: 1.25rem;
        padding: .5rem 1.25rem;
    }
    &__icon {
        --bdn-icon-color: var(--bs-blue-medium);
        height: 2.5rem;
        width: auto;
    }
}

.groupPage {
    &__image {
        width: 12rem;
        height: auto;
    }
    &__persona {
        background-color: rgba(var(--bdn-grup-color), .1);
        &__image {
            object-fit: cover;
            flex: 0 0 33.33%;
            width: 33%;
        }
    }

    @media (min-width: map-get($grid-breakpoints, "sm")) {
        &__summary {
            display: grid;
            grid-template-columns: 10rem auto;
            gap: 1rem;
        }
    }

    @media (min-width: map-get($grid-breakpoints, "lg")) {
        &__image {
            width: 25%;
            flex: 0 0 25%;
            object-fit: contain;
        }
        &__persona {         
            &__image {                
                flex-basis: 25%;
                width: 25%;
            }
        }
    }
    @media (min-width: map-get($grid-breakpoints, "xl")) {
        &__persona {         
            &__image {                
                aspect-ratio: 1 / 1;
            }
        }
    }
}
 
// ################################################################## 
// EQUIPAMENTS
.pageEquipaments {
    background-image: linear-gradient(to bottom, var(--bs-blue-light) 0, var(--bs-blue-light) calc(28.125vw + 48px), var(--bs-white) calc(28.125vw + 49px), var(--bs-white) 100%);
    &__map {
        aspect-ratio: 9 / 16;
        width: 100%;
        height: auto;        
    }
    // 480 pixels - 30em
    @media (min-width: map-get($grid-breakpoints, "sm")) {
        &__map {
            aspect-ratio: 16 / 9;
        }
    }
    @media (min-width: map-get($grid-breakpoints, "xl")) {
        background-image: linear-gradient(to bottom, var(--bs-blue-light) 0, var(--bs-blue-light) 25rem, var(--bs-white) calc(25rem + 1px), var(--bs-white) 100%);
    }
}
.equipmentItem {
    &__number {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bs-primary);
        color: var(--bs-white);
        border: 3px solid currentColor;
        box-shadow: $box-shadow;
    }
    &__image {
        transition: $transition-base;
    }

    &:hover, &:focus-within {
        .equipmentItem__image {
            transform: scale(1.1);
        }
        .equipmentItem__number {
            background-color: var(--bs-blue-dark);
        }
    }
}
.equipmentNumber {
    background-color: var(--bs-primary);
    color: var(--bs-white);
    width: 2.5rem !important;
    height: 2.5rem !important;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-left: -1.25rem !important;
    margin-top: -1.25rem !important;
    border: 3px solid var(--bs-white);
    box-shadow: $box-shadow;
    &.leaflet-marker-icon {
        display: inline-flex;
    }
}
#map-equipments {
    .leaflet-popup-tip {
        background-color: var(--bs-blue-light);
    }
    .leaflet-popup-content {
        padding-bottom: 0;
    }
    &.leaflet-container a.leaflet-popup-close-button {       
        width: 2.5rem;
        height: 2.5rem;
        background-color: transparent;
        color: var(--bs-primary);
        top: 0;
        right: 0;
        border-radius: 0;
        &:hover, &:focus {
            color: var(--bs-blue-dark);
        }  
    }
}

// ################################################################## 
// PROJECTS
.projectsMap {
    aspect-ratio: 1/1;
    width: 100%;
    height: auto; 
    .leaflet-popup-content {
        padding-bottom: 0;
    }
    .leaflet-popup-tip {
        background-color: var(--bs-blue-light);
    }
    article.item-popup { 
        display: flex; 
        flex-wrap: wrap;
        .img-and-howto { 
            width: 64px;
            img {
                aspect-ratio: 1 / 1;
                height: auto;
                object-fit: cover;
                width: 100%;
            }
            & + div { 
                flex: 1 1 200px;
            }
        }
        a.howto-btn { 
            flex: 0 0 100%;
        }
    }
    // 992 pixels - 62em
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        aspect-ratio: 16/9;        
        article.item-popup { 
            flex-direction: column;
            .img-and-howto {
                width: 100%;
                img {
                    aspect-ratio: 16 / 5;
                }
                & + div {
                    flex: 0 1 100%;
                }
            }
        }
    }
    // 1280 pixels - 75em
    @media (min-width: map-get($grid-breakpoints, "xl")) {
        article.item-popup {             
            .img-and-howto {
                img {
                    aspect-ratio: 4 / 3;
                }
            }
        }
    }
}

// ################################################################## 
// ALBUM
body.template-album_view #portal-column-content{

    .card {
        &-image {
            align-items: flex-start !important;
            img {      
                min-width: 15rem;          
                width: 100%;
                height: 14rem;
                object-fit: cover;
            }
        }
        // 480 pixels - 30em
        @media (min-width: map-get($grid-breakpoints, "sm")) {
            &-image {                
                img {
                    min-width: 20rem;
                }
            }
        }
    }
    // 1024 pixels - 64em
    @media (min-width: map-get($grid-breakpoints, "xl")) {
        #content-core > .row {
            justify-content: center;
        }
        .col {
            flex-grow: 0;
        }
    }
}

// ################################################################## 
// RESULTAT CERCA
body.col-content.template-search {    
    .searchPage .input-group {
        .form-control {
            border-radius: 1.25rem 0 0 1.25rem !important;
        }
        .btn {
            height: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    #search-filter #search-filter-toggle {
        margin-right: var(--bs-gutter-x);
    }
    .searchResults {
        .list-group-item {
            margin-bottom: 1rem;
            flex-direction: column;
            &:first-child {
                border-radius: 0;
            }
            & + .list-group-item {
                border-top-width: 1px;
            }
            & > a {
                margin-top: 1rem;
                flex: 0 1 100%;
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
    // 480 pixels - 30em
    @media (min-width: map-get($grid-breakpoints, "sm")) {
        .searchResults {
            .list-group-item {
                flex-direction: row;
                & > a {
                    margin-top: 0;
                    flex: 0 0 200px;
                    width: auto;
                }
            }
        }
    }
}
#results-count {
    align-items: center;
}

// ################################################################## 
// LANDING
    body.landing {        
        .portalHeader {
            position: relative;
        } 
        .headerBar {
            padding-left: 0;
            padding-right: .75rem;
            .logo__img {
                height: 2.5rem;
                width: auto;
            }
            &::after {
                content: none;
            }
        }
        .max-23rem{
            max-width: 23rem;
        }
        .navigationWrapper {
            background-color: var(--bdn-landing-primary, #{$primary});
            .navbar-toggler, .btn {
                color: rgb(var(--bdn-landing-contrast, 255), var(--bdn-landing-contrast, 255), var(--bdn-landing-contrast, 255));
            }
        }
        .mnPrincipal{
            --bs-navbar-color: rgba(var(--bdn-landing-contrast), var(--bdn-landing-contrast), var(--bdn-landing-contrast), .8);
            .navbar-collapse {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background-color: var(--bdn-landing-primary);
                margin-left: calc(var(--bs-gutter-x) * -0.5);
                margin-right: calc(var(--bs-gutter-x) * -0.5);
                max-width: 30rem;
            }
            .nav-item.current > .nav-link {
                color: rgba(var(--bdn-landing-contrast), var(--bdn-landing-contrast), var(--bdn-landing-contrast), .66);
                &::before {
                    background-color: currentColor;
                }
            }
            .navbar-nav > .nav-item > .nav-link {
                color: rgba(var(--bdn-landing-contrast), var(--bdn-landing-contrast), var(--bdn-landing-contrast), .66);
                padding: .5rem 0;
                
            }
            &__segon{
                &.dropdown-menu.show {
                    display: block;
                }
                &__list{
                    columns: auto;
                }
            }
            // 992 pixels - 62em
            @media (min-width: map-get($grid-breakpoints, "lg")) {
                .navbar-collapse {
                    display: block;
                    position: relative;
                    top: auto;
                    background-color: transparent;
                    margin-left: auto;
                    margin-right: 0;
                    max-width: none;
                }
                &__buttons {
                    display: none;
                }
            }
        }
        .pageHeader {
            position: absolute;
            top: 100%;
            z-index: -1;
            margin-top: -21px;
            left: 0;
            width: 100%;
            height: 22rem;
            img {
                height: 100%;
                object-fit: cover;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                background-color: rgba(var(--bdn-landing-primary-rgb), .5);
            }
        }
        .landingFirstHeading {
            text-align: center;
            color: var(--bs-white);
            span {
                background-color: rgba($gray-900,.66);
            }      
        }
        #portal-column-content {
            box-shadow: $box-shadow;
            padding: 1rem;
            background-color: var(--bs-white);
            min-height: 22rem;
        }
        #main-section {
            padding-top: 2rem;
            background-color: var(--bs-white);
        }

        // 992 pixels - 62em
        @media (min-width: map-get($grid-breakpoints, "lg")) {
            #btn-searchbox {
                order: 2;
            }
            #navbarNavDropdown {
                order: 1;
                flex: 1 1 auto;
            }
        }
    }
    
// Header
.logoLanding {
    height: 3rem;
    width: auto;
    &__link {
        background-color: transparent;
        display: block;        
    }

    // 576 pixels - 36em
    @media (min-width: map-get($grid-breakpoints, "sm")) {
        &__link {
            left: auto;
            right: 0; 
            top: 0;
        }
    }
    // 992 pixels - 62em
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        &__link {
            padding: .5rem;
        }
    }
}

// ################################################################## 
// PRESS ROOM
body.template-sala_premsa_view {
    .portletNavigationTree {
        .card-body {
            background-color: transparent;
            padding: 0;
            a {
                padding: 0;
            }
        }
        .plone-icon, .thumb-icon {
            display: none;
        }
        ul li {
            margin-top: 1rem;
            padding-top: 0.5rem;
        }
    }

    // 1024 pixels - 64em
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        &.col-two.col-content {
            #portal-column-content {                
                width: 100%;
            }
            #portal-column-two {
                width: 40%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    // 1440 pixels - 90em
    @media (min-width: map-get($grid-breakpoints, "xl" )) {
        &.col-two.col-content {
            #portal-column-content {
                width: 60%;
                margin-right: auto;
            }
            #portal-column-two {
                width: 40%;
                margin:0;
            }
        }        
    }

    // 1600 pixels - 100em
    @media (min-width: map-get($grid-breakpoints, "xxl" )) {
        &.col-two.col-content {
            #portal-column-content {
                width: 66.66%;
            }
            #portal-column-two {
                width: 33.33%;
            }
        }      
    }
}
.portlet-agenda-sala-premsa {
    &.portlet {
        box-shadow: none;
    }
    table .event {
        & > * {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:auto;
            border-radius: 50%;
        }
        &.cal_has_events > *{
            background-color: var(--bs-gray-400);
        }
        &.today > *{
            background-color: var(--bs-primary);
            color: var(--bs-white);
        }
        a {
            text-decoration: none;
        }
        // 992 pixels - 62em
        @include media-breakpoint-up(lg) {
            & > * {
                width: 100%;
                height: auto;
                aspect-ratio: 1 / 1;
            }
        }
        // 1600 pixels - 100em
        @include media-breakpoint-up(xxl) {
            & > * {
                width: 2.5rem;
                height: 2.5rem;
                aspect-ratio: auto;
            }
        }
    }
}
.multimedia-element {
    img, .plone-icon {
        transition: $transition-base;
    }
    &:hover, &:focus-within {
        img {
            opacity: 1 !important;
        }
        .plone-icon {
            opacity: 0;
        }
    }
}
.portlet-static-contacte {
    &.portlet {
        box-shadow: none;
        border: none;
    }
    .card-header {
        font-size: 1.25rem;
        padding: 0;
        background-color: transparent;
        border: none;
        margin-bottom: 1rem;
        font-family: $headings-font-family;
    }
    .card-body {
        padding: 0;
    }
}

// ################################################################## 
// BEACHES
.platgesInfo {
    // 768 pixels - 48em
    @media (min-width: map-get($grid-breakpoints, "md")) {
        &__img {
            width: 50% !important;
            flex-shrink: 0;
        }
    }
    // 992 pixels - 62em
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        &__img {
            width: 100% !important;
        }
    }
    // 1280 pixels - 75em
    @media (min-width: map-get($grid-breakpoints, "xl")) {
        &__img {
            width: 33.33% !important;
        }
    }
}
.infoBeach {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr);
    gap: .5rem;  
    &__100cpc {
        grid-column: 1 / -1;
    }
    &__icon {
        height: 1.25rem !important;
        width: 1.25rem !important;
        background-color: var(--bs-blue-light);        
        mask: var(--bdn-iconBeach) no-repeat center center / cover;
        &.flag-VERDA, &.flag-VERDE { background-color: var(--bs-green);}
        &.flag-VERMELLA, &.flag-ROJA { background-color: var(--bs-red);}
        &.flag-GROGA, &.flag-AMARILLA { background-color: var(--bs-yellow);}
        &.flag-NEGRA { background-color: var(--bs-gray-dark);}
    }
}
.plone-icon {
    .flag-VERDA &, .flag-VERDE & { color: var(--bs-green);}
    .flag-VERMELLA &, .flag-ROJA & { color: var(--bs-red);}
    .flag-GROGA &, .flag-AMARILLA & { color: var(--bs-yellow);}
    .flag-NEGRA & { color: var(--bs-gray-dark);}
}
#beachModal .btn-close {
    background-image: none;
}

// ################################################################## 
// RATE
.rateDocument {
    .thumb-rating{
        max-width: 51rem;
        float: none;
    }
    .total-thumbs-up, .total-thumbs-down { 
        
        color: currentColor; 
        .tally-total {
            color: currentColor;
        }
    }
}
.underline-yellow {
    font-weight: bolder;
	color: var(--bs-body-color);
	box-shadow: var(--bs-yellow) 0px -4px 0px inset;
	text-decoration: none;
}

// ################################################################## 
// A11Y
.btn-link:focus { 
	color: var(--bs-btn-hover-color);
}
a, button, *[tabindex="0"] {
    &.btn:focus, &:focus {
        outline: 2px dotted var(--bs-danger);
        &:active, &:hover {
            // outline: none;
        }
    }
}

// ################################################################## 
// HIGH CONTRAST
body.highContrast {
    $bdn-contrastBg:            #180059;
    background-color:           $bdn-contrastBg;
    color:                      var(--bs-white);
    --bs-primary:               #{$white};
    --bs-blue-light:            #{$white};
    --bdn-mapColor:             #{$white};
    --bdn-icon-color:           #{$white};
    --bs-yellow:                #{$white};
    --bs-link-color:            #{$white};

    * {
        background-color:       $bdn-contrastBg !important;
        color:                  var(--bs-white) !important;
        &:hover, &:focus {
            color:                  var(--bs-white) !important;
        }
    }

    .navbar-toggler-icon {
        filter: invert(100%);
        background-color: transparent !important;
    }
    .portalIcons {
        --bdn-icon-color:       #{$white};
    }
    .headerBar::after {
        display: none;
    }
    .barris {
        --bdn-mapColor:         #{$white};
    }
    .bdnSlideDots .owl-dot {
        border: 1px solid $white;
        &.active {
            background-color: $white !important;
            
        }
    }
    .btn-primary {
        --bs-btn-border-color: #{$white};
    }
    
}
.modal .pagination { 
	--bs-pagination-padding-x: 0.5rem;
	--bs-pagination-padding-y: 0.25rem;
	--bs-pagination-font-size: 0.875rem;
	--bs-pagination-border-radius: 0.25rem;
    .page-link .label { 
        @extend .visually-hidden;
    }
}

// ##################################################################
// PRINT
@media print {
    .link-light { color: $black !important;}
}
@media (min-width: map-get($grid-breakpoints, "lg")) {
    .bottom-lg-0{
        bottom: 0;
    }
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    .bottom-md-0{
        bottom: 0;
    }
}