@media print {

    // ################################################################## 
    // HEADER
    .headerBar::after {
        content: none;
    }

    // ################################################################## 
    // HOME
    .portalSlider {
        &__text {
            position: static
        }
        &__image {
            height: auto;
        }
        .owl-carousel .owl-stage {
            display: flex; 
            flex-wrap: wrap; 
            width:100% !important;
            transform: none !important;
        }
        .owl-item {
            width: 45% !important; 
           
            margin: 1rem;
            &.cloned {
                display: none;
            }
        }
    }

    .ecoInfo {
        background-color: transparent;
        backdrop-filter: none;
    }

    #home-news, #events-section {
        .col {
            width: 50%;
        }
    }

    #myTabContent {display: flex;}
    #districtesWrapepr,  #barrisWrapper{
        display: block;
        width: 50%;
        opacity: 1;
    }

    // ################################################################## 
    // MINISITE
    .minisiteHeader {
        &__image, &::after {
            display: none;
        }
    }
    .minisiteSlide {
        &__item { 
            background-color: $white !important;
            flex-direction: column;
        }
        &.owl-carousel .owl-stage {
            display: flex; 
            flex-wrap: wrap; 
            width:100% !important;
            transform: none !important;
        }
        .owl-item {
            width: 25% !important; 
            margin: 1rem;
            &.cloned {
                display: none;
            }
        }
    }
    .progproj::after {
        display: none;
    }

    // ################################################################## 
    // INTERIOR 
    .portletNavigationTree, #page-header {
        display: none !important;
    }
}
