figure,
figcaption { display: block;}
body.icons-off .portletNavigationTree .plone-icon { display: none;}
body .select2-results .select2-highlighted { color: var(--bs-white); }
.ordered-selection-field {
    // 480 pixels - 30em
    @media (min-width: map-get($grid-breakpoints, "sm")) {        
        td + td{ padding: 0 0.5rem; text-align: center;}
        select { min-width: 10rem;}        
        td:nth-child(3) { vertical-align: top;}
    } 
}
article#content > header { margin-bottom: 0;}
.select-icon { padding: 0.5rem;}