/**
   Custom css for Klaro, a cookie consent manager
**/

#cookieklaro {

    .cm-list-input:checked + .cm-list-label .slider,
    .cm-list-input.only-required + .cm-list-label .slider,
    .cm-list-input.required:checked + .cm-list-label .slider,
    .cm-list-input.required:checked + .cm-list-label .slider,
    .cm-list-input.required:checked + .cm-list-label .slider {
        background-color: $success !important;
    }

    .cm-list-description {
        color: #c5c5c5 !important;
    }

    .cm-services .cm-content.expanded {
        padding-left: 40px;
    }

    .text-justify {
        text-align: justify !important;
    }

    .text-primary {
        color: $primary !important;
    }

    .cookie-modal a {
        color: var(--bs-link-color) !important;
    }

    .cm-btn-info,
    .cm-btn-success,
    .cm-footer-buttons .cm-btn-success{
        @include button-variant(
            $info,
            $info,
            $hover-background: shade-color($info, $btn-hover-bg-shade-amount),
            $hover-border: shade-color($info, $btn-hover-border-shade-amount),
            $active-background: shade-color($info, $btn-active-bg-shade-amount),
            $active-border: shade-color($info, $btn-active-border-shade-amount)
        );
    }
    .cm-btn-success {
        @include button-variant(
            $success,
            $success,
            $hover-background: shade-color($success, $btn-hover-bg-shade-amount),
            $hover-border: shade-color($success, $btn-hover-border-shade-amount),
            $active-background: shade-color($success, $btn-active-bg-shade-amount),
            $active-border: shade-color($success, $btn-active-border-shade-amount)
        );
    }
    .cm-btn-danger {
        @include button-variant(
            $danger,
            $danger,
            $hover-background: shade-color($danger, $btn-hover-bg-shade-amount),
            $hover-border: shade-color($danger, $btn-hover-border-shade-amount),
            $active-background: shade-color($danger, $btn-active-bg-shade-amount),
            $active-border: shade-color($danger, $btn-active-border-shade-amount)
        );
    }

    .cn-body {

        .cn-ok {
            margin-top: 2rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .cn-learn-more {
              text-decoration: none;
              color: var(--bs-primary);
                margin-top: 0;
                background-color: var(--bs-white);
                border-radius: 6px;
                padding: 6px 10px;
                margin-right: 0.5em;
                border: 0;
                padding: 0.4em;
                font-size: 1em;
                cursor: pointer;
                flex-grow: 0;
            }

            .cn-learn-more {
                @include button-variant(
                    $info,
                    $info,
                    $hover-background: shade-color($info, $btn-hover-bg-shade-amount),
                    $hover-border: shade-color($info, $btn-hover-border-shade-amount),
                    $active-background: shade-color($info, $btn-active-bg-shade-amount),
                    $active-border: shade-color($info, $btn-active-border-shade-amount)
                );
            }

            .cn-buttons {
                margin-top: 0;

                .cm-btn {
                    margin: 0 !important;
                    &.cn-decline {
                        margin-right: 0.5rem !important;
                    }
                }
            }
        }
    }
}
/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/* Colors */
/* Spacing */
/* Breakpoints */
.klaro.cm-as-context-notice {
    height: 100%;
    padding-bottom: 12px;
    padding-top: 12px;
  }
  
  .klaro .cookie-modal,
  .klaro .context-notice,
  .klaro .cookie-notice {
    /* The switch - the box around the slider */
    font-size: 14px;
  }
  
  .klaro .cookie-modal .cm-switch-container,
  .klaro .context-notice .cm-switch-container,
  .klaro .cookie-notice .cm-switch-container {
    display: block;
    position: relative;
    padding: 10px;
    padding-left: 66px;
    line-height: 20px;
    vertical-align: middle;
    min-height: 40px;
    border-bottom: 1px solid #ccc;
  }
  
  .klaro .cookie-modal .cm-switch-container:last-child,
  .klaro .context-notice .cm-switch-container:last-child,
  .klaro .cookie-notice .cm-switch-container:last-child {
    border-bottom: 0;
  }
  
  .klaro .cookie-modal .cm-switch-container:first-child,
  .klaro .context-notice .cm-switch-container:first-child,
  .klaro .cookie-notice .cm-switch-container:first-child {
    margin-top: 0;
  }
  
  .klaro .cookie-modal .cm-switch-container p,
  .klaro .context-notice .cm-switch-container p,
  .klaro .cookie-notice .cm-switch-container p {
    margin-top: 0;
  }
  
  .klaro .cookie-modal .cm-switch,
  .klaro .context-notice .cm-switch,
  .klaro .cookie-notice .cm-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;
  }
  
  .klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
  .klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
    background-color: #1a936f;
    background-color: var(--green1, #1a936f);
  }
  
  .klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
  .klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
    background-color: #1a936f;
    background-color: var(--green1, #1a936f);
    opacity: 0.6;
  }
  
  .klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
  .klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
  .klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
    transform: translateX(10px);
  }
  
  .klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
  .klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
    background-color: #24cc9a;
    background-color: var(--green2, #24cc9a);
    opacity: 0.8;
  }
  
  .klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
  .klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider::before,
  .klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
    transform: translateX(10px);
  }
  
  .klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
  .klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
    background-color: #24cc9a;
    background-color: var(--green2, #24cc9a);
    opacity: 0.8;
    cursor: not-allowed;
  }
  
  .klaro .cookie-modal .slider,
  .klaro .context-notice .slider,
  .klaro .cookie-notice .slider {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  }
  
  .klaro .cookie-modal .cm-list-input,
  .klaro .context-notice .cm-list-input,
  .klaro .cookie-notice .cm-list-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 50px;
    height: 30px;
  }
  
  .klaro .cookie-modal .cm-list-title,
  .klaro .context-notice .cm-list-title,
  .klaro .cookie-notice .cm-list-title {
    font-size: 14px;
    font-weight: 600;
  }
  
  .klaro .cookie-modal .cm-list-description,
  .klaro .context-notice .cm-list-description,
  .klaro .cookie-notice .cm-list-description {
    font-size: 14px;
    padding-top: 4px;
    color: #666;
  }
  
  .klaro .cookie-modal .cm-list-label,
  .klaro .context-notice .cm-list-label,
  .klaro .cookie-notice .cm-list-label {
    /* The slider */
    /* Rounded sliders */
  }
  
  .klaro .cookie-modal .cm-list-label .cm-switch,
  .klaro .context-notice .cm-list-label .cm-switch,
  .klaro .cookie-notice .cm-list-label .cm-switch {
    position: absolute;
    left: 0;
  }
  
  .klaro .cookie-modal .cm-list-label .slider,
  .klaro .context-notice .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-label .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    width: 50px;
    display: inline-block;
  }
  
  .klaro .cookie-modal .cm-list-label .slider::before,
  .klaro .context-notice .cm-list-label .slider::before,
  .klaro .cookie-notice .cm-list-label .slider::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 5px;
    background-color: #fff;
    transition: 0.4s;
  }
  
  .klaro .cookie-modal .cm-list-label .slider.round,
  .klaro .context-notice .cm-list-label .slider.round,
  .klaro .cookie-notice .cm-list-label .slider.round {
    border-radius: 30px;
  }
  
  .klaro .cookie-modal .cm-list-label .slider.round::before,
  .klaro .context-notice .cm-list-label .slider.round::before,
  .klaro .cookie-notice .cm-list-label .slider.round::before {
    border-radius: 50%;
  }
  
  .klaro .cookie-modal .cm-list-label input:focus + .slider,
  .klaro .context-notice .cm-list-label input:focus + .slider,
  .klaro .cookie-notice .cm-list-label input:focus + .slider { 
    box-shadow: 0 0 1px #48dfb2;
  }
  
  .klaro .cookie-modal .cm-list-label input:checked + .slider::before,
  .klaro .context-notice .cm-list-label input:checked + .slider::before,
  .klaro .cookie-notice .cm-list-label input:checked + .slider::before {
    transform: translateX(20px);
  }
  
  .klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
  .klaro .context-notice .cm-list-input:focus + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
    box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
  }
  
  .klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
  .klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before,
  .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
    transform: translateX(20px);
  }
  
  .klaro .cookie-modal .slider,
  .klaro .context-notice .slider,
  .klaro .cookie-notice .slider {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  }
  
  .klaro .cookie-modal a,
  .klaro .context-notice a,
  .klaro .cookie-notice a {
    color: var(--bs-link-color); 
    text-decoration: underline;
  }
  
  .klaro .cookie-modal p,
  .klaro .cookie-modal strong,
  .klaro .cookie-modal h1,
  .klaro .cookie-modal h2,
  .klaro .cookie-modal ul,
  .klaro .cookie-modal li,
  .klaro .context-notice p,
  .klaro .context-notice strong,
  .klaro .context-notice h1,
  .klaro .context-notice h2,
  .klaro .context-notice ul,
  .klaro .context-notice li,
  .klaro .cookie-notice p,
  .klaro .cookie-notice strong,
  .klaro .cookie-notice h1,
  .klaro .cookie-notice h2,
  .klaro .cookie-notice ul,
  .klaro .cookie-notice li {
    color: #eee;
    color: var(--font-color-dark, #eee);
  }
  
  .klaro .cookie-modal p,
  .klaro .cookie-modal h1,
  .klaro .cookie-modal h2,
  .klaro .cookie-modal ul,
  .klaro .cookie-modal li,
  .klaro .context-notice p,
  .klaro .context-notice h1,
  .klaro .context-notice h2,
  .klaro .context-notice ul,
  .klaro .context-notice li,
  .klaro .cookie-notice p,
  .klaro .cookie-notice h1,
  .klaro .cookie-notice h2,
  .klaro .cookie-notice ul,
  .klaro .cookie-notice li {
    display: block;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 0.7em;
  }
  
  .klaro .cookie-modal .cm-link,
  .klaro .context-notice .cm-link,
  .klaro .cookie-notice .cm-link {
    margin-right: 0.5em;
    vertical-align: middle;
  }
  
  .klaro .cookie-modal .cm-btn,
  .klaro .context-notice .cm-btn,
  .klaro .cookie-notice .cm-btn {
    color: #eee;
    color: var(--font-color-dark, #eee);
    background: #555;
    border-radius: 6px;
    padding: 6px 10px;
    margin-right: 0.5em;
    border: 0;
    padding: 0.4em;
    font-size: 1em;
    cursor: pointer;
  }
  
  .klaro .cookie-modal .cm-btn:disabled,
  .klaro .context-notice .cm-btn:disabled,
  .klaro .cookie-notice .cm-btn:disabled {
    opacity: 0.5;
  }
  
  .klaro .cookie-modal .cm-btn.cm-btn-close,
  .klaro .context-notice .cm-btn.cm-btn-close,
  .klaro .cookie-notice .cm-btn.cm-btn-close {
    background: #aaa;
    color: #000;
  }
  
  .klaro .cookie-modal .cm-btn.cm-btn-success,
  .klaro .context-notice .cm-btn.cm-btn-success,
  .klaro .cookie-notice .cm-btn.cm-btn-success {
    background: var(--bs-priamry);
    color: var(--bs-white);
  }
  
  .klaro .cookie-modal .cm-btn.cm-btn-success-var,
  .klaro .context-notice .cm-btn.cm-btn-success-var,
  .klaro .cookie-notice .cm-btn.cm-btn-success-var {
    background: var(--bs-primary);
    color: var(--bs-white);
  }
  
  .klaro .cookie-modal .cm-btn.cm-btn-info,
  .klaro .context-notice .cm-btn.cm-btn-info,
  .klaro .cookie-notice .cm-btn.cm-btn-info {
    background: #2581c4;
    background: var(--blue1, #2581c4);
  }
  
  .klaro .context-notice {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #eee;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 12px;
  }
  
  .klaro .context-notice p {
    flex-grow: 0;
    text-align: center;
    padding-top: 0;
    margin-top: 0;
    color: #000;
  }
  
  .klaro .context-notice p strong {
    color: #333;
  }
  
  .klaro .context-notice p a {
    color: #459cdc;
  }
  
  .klaro .context-notice p.cm-buttons {
    margin-top: 12px;
  }
  
  .klaro .cookie-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: 1000;
  }
  
  .klaro .cookie-modal.cm-embedded {
    position: relative;
    height: inherit;
    width: inherit;
    left: inherit;
    right: inherit;
    z-index: 0;
  }
  
  .klaro .cookie-modal.cm-embedded .cm-modal.cm-klaro {
    position: relative;
    transform: none;
  }
  
  .klaro .cookie-modal .cm-bg {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .klaro .cookie-modal .cm-modal.cm-klaro {
    z-index: 1001;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    max-height: 98%;
    top: 50%;
    transform: translateY(-50%);
    position: fixed;
    overflow: auto;
    background: #333;
    color: #eee;
  }
  
  @media (min-width: 660px) {
    .klaro .cookie-modal .cm-modal.cm-klaro {
      border-radius: 4px;
      position: relative;
      margin: 0 auto;
      max-width: 640px;
      height: auto;
      width: auto;
    }
  }
  
  .klaro .cookie-modal .cm-modal .hide {
    border-style: none;
    background: none;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
  
  .klaro .cookie-modal .cm-modal .hide svg {
    stroke: #eee;
  }
  
  .klaro .cookie-modal .cm-modal .cm-footer {
    padding: 1em;
    border-top: 1px solid #555;
  }
  
  .klaro .cookie-modal .cm-modal .cm-footer-buttons {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
  
  .klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
    font-size: 0.8em;
    padding-top: 4px;
    text-align: right;
    padding-right: 8px;
  }
  
  .klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
    color: #999;
  }
  
  .klaro .cookie-modal .cm-modal .cm-header {
    padding: 1em;
    padding-right: 24px;
    border-bottom: 1px solid #555;
  }
  
  .klaro .cookie-modal .cm-modal .cm-header h1 {
    margin: 0;
    font-size: 2em;
    display: block;
  }
  
  .klaro .cookie-modal .cm-modal .cm-header h1.title {
    padding-right: 20px;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body {
    padding: 1em;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul {
    display: block;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body span {
    display: inline-block;
    width: auto;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
    padding: 0;
    margin: 0;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-caret,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-caret {
    color: #888;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
    margin-left: -40px;
    display: none;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
    margin-top: 10px;
    display: block;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
    position: relative;
    line-height: 20px;
    vertical-align: middle;
    padding-left: 60px;
    min-height: 40px;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service:first-child,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose:first-child,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service:first-child,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
    margin-top: 0;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
    margin-top: 0;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p.purposes,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p.purposes,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p.purposes,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
    font-size: 0.8em;
    color: #999;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service.cm-toggle-all,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose.cm-toggle-all,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service.cm-toggle-all,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
    border-top: 1px solid #555;
    padding-top: 1em;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-list-title,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-list-title,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-list-title,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
    font-weight: 600;
  }
  
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-opt-out,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-required,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-opt-out,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-required,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-opt-out,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-required,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
  .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
    padding-left: 0.2em;
    font-size: 0.8em;
    color: #999;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    background: #333;
    z-index: 999;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
  }
  
  @media (min-width: 1024px) {
    .klaro .cookie-notice:not(.cookie-modal-notice) {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
      border-radius: 4px;
      position: fixed;
      bottom: 20px;
      right: 20px;
      max-width: 400px;
    }
  }
  
  @media (max-width: 1023px) {
    .klaro .cookie-notice:not(.cookie-modal-notice) {
      border-style: none;
      border-radius: 0;
    }
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
    position: relative;
    height: inherit;
    width: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    z-index: 0;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
    padding-top: 0.5em;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
    margin-bottom: 0;
    margin-right: 0;
    bottom: 0;
    padding: 1em;
    padding-top: 0;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
    margin-bottom: 0.5em;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
    text-decoration: underline;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
    display: inline-block;
    flex-grow: 1;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
    display: inline-block;
    margin-top: -0.5em;
  }
  
  @media (max-width: 384px) {
    .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
      width: 100%;
    }
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
    margin-top: 0.5em;
  }
  
  @media (max-width: 384px) {
    .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
      width: calc(50% - 0.5em);
    }
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
    margin-top: -0.5em;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: right;
    align-items: baseline;
  }
  
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok div {
    margin-top: 0.5em;
  }
  
  .klaro .cookie-modal-notice {
    z-index: 1001;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    max-height: 98%;
    top: 50%;
    transform: translateY(-50%);
    position: fixed;
    overflow: auto;
    background: #333;
    color: #eee;
    padding: 1em;
    padding-top: 0.2em;
  }
  
  @media (min-width: 400px) {
    .klaro .cookie-modal-notice {
      border-radius: 4px;
      position: relative;
      margin: 0 auto;
      max-width: 400px;
      height: auto;
      width: auto;
    }
  }
  
  .klaro .cookie-modal-notice .cn-ok {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
  }
  
  .klaro .cookie-notice-hidden {
    display: none !important;
  }
  