/* tinyMCE format examples
   Styles in will be automatically added to the top level of the formats menu.
   Condition is that the file is named tinymce-formats.css and registered in the TinyMCE controlpanel.
*/

.highlight-inline {
	background: var(--bs-blue-light);
}

p.highlight-paragraph {
	background: var(--bs-blue-light);
	padding: 1rem;
}

table.table.table-striped {
	--bs-table-striped-bg: var(--bs-blue-light);
}

a.btn.btn-primary {
	background-color: var(--bs-primary);
	color: var(--bs-white);
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
}
a.btn.btn-outline-primary {
	color: var(--bs-btn-color);
	border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
}
a.btn.btn-outline-success {
	color: var(--bs-btn-color);
	border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
}
p.alert.alert-secondary {
	background-color: #e2e3e5;
	color: var(--bs-body-color);
	padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
}
.underline-yellow {
	font-weight: bolder;
	color: var(--bs-body-color);
	box-shadow: var(--bs-yellow) 0px -4px 0px inset;
	text-decoration: none;
}